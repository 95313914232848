import React from "react";
import { NavLink } from 'react-router-dom'

const Footer = () => {
  return (
      <footer className="footer">
            <div className="container">
                <NavLink className="linkedin_footer" to="https://www.linkedin.com/company/wednesday-women">
                  <img src="../assets/images/image_linkedin.svg" alt="linkedin img" />
                </NavLink>
                <p className="footer_bottom">© 2025 by Wednesday Women LLC.</p>
            </div>
      </footer>
  );
};

export default Footer;

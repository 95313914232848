import React, { useEffect, useState } from "react";
import { Footer, Navbar, Filter, SpeakerList } from "../components";
import axios from "../axiosCall";
// eslint-disable-next-line
import BackToTop from "react-back-to-top-button";

import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
let count = 0;
const Speakers = () => {
  const [speakerDataFromAPI, setSpeakerDataFromAPI] = useState([]);
  const [isCall, setIsCall] = useState(true);
  const [
    pageSize,
    // eslint-disable-next-line
    setPageSize,
  ] = useState(27); // Changed page size to 12 because stopped loader.
  const [isCompleted, setIsCompleted] = useState(false);
  const [offsetValue, setOffsetValue] = useState("");

  // const [isLoaded, setIsLoaded] = useState(false)
  const [noData, setNoData] = useState(false);
  const [isExceptionError, setIsExceptionError] = useState(false);

  const [sortBy, setSortBy] = useState("all");
  const [search, setSearch] = useState(
    () => sessionStorage.getItem("searchTerm") || ""
  );
  const [FunctionFilter, setFunctionFilter] = useState("");
  const [OthersFilter, setOthersFilter] = useState("");
  const [IndustryFilter, setIndustryFilter] = useState("");
  const [CountryFilter, setCountryFilter] = useState("");
  const [podcastFilter, setPodcastFilter] = useState("");
  const [linkedInFilter, setLinkedInFilter] = useState("");

  const [tagValue, settagValue] = useState("");

  let filterQuery = "";
  // let filterData;
  let filterData = "AND(";
  let queryString;
  const {
    // eslint-disable-next-line
    ref,
    inView,
  } = useInView();
  useEffect(() => {
    if (isCall) {
      fetchSpeakerListData();
    }
    // eslint-disable-next-line
  }, [pageSize]);

  useEffect(() => {
    if (isCall === false) {
      setSpeakerDataFromAPI([]);
      fetchSpeakerListData();
    }
    // eslint-disable-next-line
  }, [
    sortBy,
    // search,
    FunctionFilter,
    OthersFilter,
    IndustryFilter,
    CountryFilter,
    podcastFilter,
    linkedInFilter,
  ]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isCall === false) {
        setSpeakerDataFromAPI([]);
        fetchSpeakerListData();
      }
      
    }, 300); // Adjust debounce delay as needed
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [search]);
  

  useEffect(() => {
    if (inView) {
      if (isCall === false && isCompleted === false) {
        fetchSpeakerListData();
      }
    }
    // eslint-disable-next-line
  }, [inView, isCompleted]);

  useEffect(() => {
    if (noData === true || isExceptionError === true) setSpeakerDataFromAPI([]);
  }, [noData, isExceptionError]);

  const fetchSpeakerListData = async () => {
    // console.log('fetch');
    // setIsLoaded(true)
    setNoData(false);
    setIsExceptionError(false);
    try {
      axios.defaults.headers.common.Authorization = `Bearer patuPk8JwEiXEZFnO.bf2abcc1730649a6551491e354d7aba1d372370b442c2c16bfc152a55f2be665`;

      let featuredSorting = "";
      let defaultSorting = "&sort[0][field]=Name&sort[0][direction]=asc";
      queryString = createFilterByFormula();
      // console.log(queryString);
      const response = await axios.get(
        "tblnBF0EWDRYNq08H?pageSize=" +
          pageSize +
          "&offset=" +
          offsetValue +
          defaultSorting +
          featuredSorting +
          queryString
      );

      const { records, offset } = response.data;
      setIsCall(false);

      if (offset) {
        setOffsetValue(offset);
        setIsCompleted(false);
        document.querySelector(".custom_pagination").style.display = "flex";
      } else {
        setIsCompleted(true);
        setOffsetValue("");
        document.querySelector(".custom_pagination").style.display = "none";
      }

      if (records.length > 0) {
        // eslint-disable-next-line
        // if (records.length === 1 || sessionStorage.getItem("searchTerm")) {
        //   setSpeakerDataFromAPI([]); // Need to update the condition here for search only.
        // }
        // console.log(records);
        setNoData(false);
        setIsExceptionError(false);
        const uniqueRecords = [
          ...new Map(
            [...speakerDataFromAPI, ...records].map((item) => [item.id, item])
          ).values(),
        ];
  
        setSpeakerDataFromAPI(uniqueRecords);
        let tags = [];
        const result = await axios.get("tblnBF0EWDRYNq08H");
        if (result.data.offset) {
          tags = tags.concat(result.data.records);
          const result1 = await axios.get(
            "tblnBF0EWDRYNq08H?offset=" + result.data.offset
          );
          if (result1.data.offset) {
            tags = tags.concat(result1.data.records);
            const result2 = await axios.get(
              "tblnBF0EWDRYNq08H?offset=" + result1.data.offset
            );
            if (result2.data.offset) {
              tags = tags.concat(result2.data.records);
              // eslint-disable-next-line
              const result3 = await axios.get(
                "tblnBF0EWDRYNq08H?offset=" + result2.data.offset
              );
            } else {
              tags = tags.concat(result2.data.records);
            }
          }
        }
        settagValue(tags);
      } else {
        setNoData(true);
        setIsCompleted(true);
        setOffsetValue("");
        // setIsLoaded(false)
        setSpeakerDataFromAPI([]);
      }
    } catch (err) {
      setIsExceptionError(true);
      // setIsLoaded(false)
      setSpeakerDataFromAPI([]);
    }
  };

  const createFilterByFormula = () => {
    if (search) {
      // eslint-disable-next-line
      let searchData = "'" + search + "'";
      searchData = searchData.toLowerCase();
      //searchData = "&filterByFormula=SEARCH( "+searchData+", LOWER({Name}))";
      filterData +=
        "OR(SEARCH( " +
        searchData +
        ", LOWER({Name})), SEARCH( " +
        searchData +
        ", LOWER({Last Name})), SEARCH( " +
        searchData +
        ", LOWER({Role-Manual})), SEARCH( " +
        searchData +
        ", LOWER({role})), SEARCH( " +
        searchData +
        ", LOWER({Company-Manual})), SEARCH( " +
        searchData +
        ", LOWER({company})))";
      sessionStorage.setItem("searchTerm", search);
    } else {
      // console.log('yes');
      // console.log(filterData);
      sessionStorage.removeItem("searchTerm");
    }
    if (FunctionFilter.length > 0) {
      for (let s = 0; s < FunctionFilter.length; s++) {
        // eslint-disable-next-line
        let filterValue = '"' + FunctionFilter[s] + '"';
        if (filterData === "AND(") {
          if (s === 0) {
            filterData +=
              "AND(REGEX_MATCH({FunctionTag} , " + filterValue + ")";
          } else {
            filterData += ", REGEX_MATCH({FunctionTag} , " + filterValue + ")";
          }
        } else {
          if (s === 0) {
            filterData +=
              ", AND(REGEX_MATCH({FunctionTag} , " + filterValue + ")";
          } else {
            filterData += ", REGEX_MATCH({FunctionTag} , " + filterValue + ")";
          }
        }
      }
      filterData += ")";
    }
    if (OthersFilter.length > 0) {
      for (let s = 0; s < OthersFilter.length; s++) {
        // eslint-disable-next-line
        let filterValue = '"' + OthersFilter[s] + '"';
        if (filterData === "AND(") {
          if (s === 0) {
            filterData += "AND(REGEX_MATCH({OtherTag}, " + filterValue + ")";
          } else {
            filterData += ", REGEX_MATCH({OtherTag}, " + filterValue + ")";
          }
        } else {
          if (s === 0) {
            filterData += ", AND(REGEX_MATCH({OtherTag}, " + filterValue + ")";
          } else {
            filterData += ", REGEX_MATCH({OtherTag}, " + filterValue + ")";
          }
        }
      }
      filterData += ")";
    }
    if (IndustryFilter.length > 0) {
      for (let s = 0; s < IndustryFilter.length; s++) {
        // eslint-disable-next-line
        let filterValue = '"' + IndustryFilter[s] + '"';
        if (filterData === "AND(") {
          if (s === 0) {
            filterData +=
              "AND(REGEX_MATCH({IndustryTag} , " + filterValue + ")";
          } else {
            filterData += ", REGEX_MATCH({IndustryTag} , " + filterValue + ")";
          }
        } else {
          if (s === 0) {
            filterData +=
              ", AND(REGEX_MATCH({IndustryTag} , " + filterValue + ")";
          } else {
            filterData += ", REGEX_MATCH({IndustryTag} , " + filterValue + ")";
          }
        }
      }
      filterData += ")";
    }
    if (CountryFilter.length > 0) {
      for (let s = 0; s < CountryFilter.length; s++) {
        // eslint-disable-next-line
        let filterValue = '"' + CountryFilter[s] + '"';
        if (filterData === "AND(") {
          if (s === 0) {
            filterData += "OR(REGEX_MATCH({Region} , " + filterValue + ")";
          } else {
            filterData += ", REGEX_MATCH({Region} , " + filterValue + ")";
          }
        } else {
          if (s === 0) {
            filterData += ", OR(REGEX_MATCH({Region} , " + filterValue + ")";
          } else {
            filterData += ", REGEX_MATCH({Region} , " + filterValue + ")";
          }
        }
      }
      filterData += ")";
    }

    if (podcastFilter) {
      // eslint-disable-next-line
      let filterValue = '"' + podcastFilter + '"';
      if (filterData === "AND(") {
        filterData += "{Podcast} = " + filterValue;
      } else {
        filterData += ", {Podcast} = " + filterValue;
      }
    }
    if (linkedInFilter) {
      if (filterData === "AND(") {
        if (linkedInFilter === "Yes") filterData += "{LinkedIn} != ''";
        else filterData += "{LinkedIn} = ''";
      } else {
        if (linkedInFilter === "Yes") filterData += ", {LinkedIn} != ''";
        else filterData += ", {LinkedIn} = ''";
      }
    }
    if (sortBy) {
      if (filterData === "AND(") {
        if (sortBy === "featured") filterData += "{Featured_On} != ''";
        else if (sortBy === "not_featured") filterData += "{Featured_On} = ''";
      } else {
        if (sortBy === "featured") filterData += ", {Featured_On} != ''";
        else if (sortBy === "not_featured")
          filterData += ", {Featured_On} = ''";
      }
    }
    if (filterData === "AND(") {
      filterData += "{Name} != ''";
    } else {
      filterData += ", {Name} != ''";
    }

    filterData += ")";
    filterQuery += filterData;
    return "&filterByFormula=" + encodeURIComponent(filterQuery);
  };

  const createLists = () => {
    return speakerDataFromAPI.map((x, index) => {
      return (
        <SpeakerList
          key={index}
          speakerDataFromAPI={speakerDataFromAPI}
          index={index}
        ></SpeakerList>
      );
    });
  };
  const sortingCall = (sort) => {
    setOffsetValue("");
    setSpeakerDataFromAPI([]);
    setSortBy(sort);
  };

  const searchingCall = (search) => {
    setOffsetValue("");
    setSpeakerDataFromAPI([]); // Reset the speaker data
    setSearch(search);
    sessionStorage.setItem("searchTerm", search);
    if (search === "") {
      // Reset to the initial state if the search term is cleared
      setSpeakerDataFromAPI([]); // Reset the data to initial state or handle as per your initial load
      fetchSpeakerListData(); // Fetch all records again
    }
  };

  const filterCallFromComponent = (
    functionFilter,
    OthersFilter,
    IndustryFilter,
    CountryFilter,
    podcastFilter,
    linkedInFilter,
    isClearFilter
  ) => {
    if (isClearFilter) {
      setSearch("");
      sessionStorage.removeItem("searchTerm");
    }
    setOffsetValue("");
    setSpeakerDataFromAPI([]);
    // setFunctionFilter(functionFilter.filter((item) => item !== ""));
    setFunctionFilter(sessionStorage.getItem('FunctionFilter')?.split(',') || [])
    setOthersFilter(sessionStorage.getItem('OthersFilter')?.split(',') || []);
    setIndustryFilter(sessionStorage.getItem('IndustryFilter')?.split(',') || []);
    setCountryFilter(sessionStorage.getItem('CountryFilter')?.split(',') || []);
    setPodcastFilter(podcastFilter);
    setLinkedInFilter(linkedInFilter);
  };

  if (sessionStorage.getItem("sortValue") && count === 0) {
    sortingCall(sessionStorage.getItem("sortValue"));
    count = count + 1;
  }

  return (
    <>
      <Navbar />
      <section className="products-list-section">
        <div className="container">
          <div className="leaders_heading">
            <h1>Wednesday Women Directory </h1>
            <p>
              Search and discover executive women leaders featured or nominated
              as Wednesday Women, across industry, role, and more.
            </p>
          </div>
          <div className="row">
            <Filter
              searchingCall={searchingCall}
              filterCallFromComponent={filterCallFromComponent}
              sortingCall={sortingCall}
              tags={tagValue}
            >
              {" "}
            </Filter>
            <div className="col-lg-9">
              <div className="card-list-container">
                {/* <Sorting sortingCall={sortingCall} > </Sorting > */}
                {/* No records found */}
                {noData &&
                  isExceptionError === false &&
                  speakerDataFromAPI.length === 0 && (
                    <div className="no-records-found">
                      <p className="desc">No Record Found</p>
                    </div>
                  )}
                {isExceptionError &&
                  noData === false &&
                  speakerDataFromAPI.length === 0 && (
                    <div className="no-records-found">
                      <p className="desc">
                        There is something wrong. Please try after some time.
                      </p>
                    </div>
                  )}
                {/* End */}
                <div className="row row-cols-1 row-cols-md-3 g-4">
                  {speakerDataFromAPI.length > 0 ? createLists() : ""}
                </div>
                {/* start loader */}
                {/* Loader commented for footer*/}
                {
                  // (isCompleted === false) && ( <div
                  //   ref={ref}
                  //   className="text-center"
                  // >
                  //   <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                  // </div>)
                }
                {/* <button className='see-more' onClick={fetchSpeakerListData}>See More</button> */}
                {/* End Loader commented for footer*/}
                <BackToTop
                  showOnScrollDown
                  showAt={50}
                  speed={3000}
                  easing="easeInOutQuint"
                >
                  <button className="myBtn">
                    <i className="fa fa-angle-up" aria-hidden="true"></i>
                  </button>
                </BackToTop>
                {/* End */}
              </div>
              <div className="custom_pagination">
                <ul className="paginator" onClick={fetchSpeakerListData}>
                  <li>
                    {/* <Link to="/" className='page_counting active_page'>See More</Link> */}
                    <button className="see-more">See More</button>
                  </li>
                </ul>
              </div>

              <div className="nominate">
                <Link
                  to="https://info.wednesdaywomen.org/nomination"
                  target="_blank"
                  rel="noreferrer"
                  className="nominate_link"
                >
                  Nominate Someone Today
                </Link>
              </div>
              <div className="partner_logos">
                <h2>Wednesday Women Sponsors & Partners</h2>
                <ul>
                  <li>
                    <img src="assets/images/1.png" alt="" />
                  </li>
                  <li>
                    <img src="assets/images/2.png" alt="" />
                  </li>
                  <li>
                    <img src="assets/images/3.png" alt="" />
                  </li>
                  <li>
                    <img src="assets/images/4.png" alt="" />
                  </li>
                  <li>
                    <img src="assets/images/5.png" alt="" />
                  </li>
                  <li>
                    <img src="assets/images/6.png" alt="" />
                  </li>
                  <li>
                    <img src="assets/images/7.png" alt="" />
                  </li>
                  <li>
                    <img src="assets/images/8.png" alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Speakers;
